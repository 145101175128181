import React from "react";
import DateTime from "react-datetime";
import moment from 'moment';
import { apiNewNotificacion } from '../../API/apiNotifications';
import { apiGetSuscriptions } from "../../API/api";

const NewNotification = (props) => {
  const { showLoader, NotificationManager } = props;
  const [form, setForm] = React.useState({});
  const goBack = () => {
    props.history.goBack();
  }

  const handleChangeFormsValue = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setForm({ ...form, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity() && form.topic) {
      showLoader(true);
      apiNewNotificacion(form)
        .then(response => {
          console.log(response);
          if (!response.message) {
            NotificationManager.success("Se ha creado la notificación correctamente.", "¡Éxito!", 1000);
            props.history.push("/notificaciones");
          } else {
            NotificationManager.error("No se ha podido crear la notificación.", "¡Error!", 5000);
          }
        })
        .catch(() => {
          NotificationManager.error("No se ha podido crear la notificación.", "¡Error!", 5000);
        })
        .finally(() => {
          showLoader(true);
        })
    }
  }

  const [suscripciones, setSuscripciones] = React.useState([]);

  React.useEffect(() => {
    async function getSuscripciones() {
      try {
        const resp = await apiGetSuscriptions({
          limit: 99
        });
        if (!resp.error) {
          setSuscripciones(resp.rows);
        }
      } catch (error) {
        console.error(error);
      }
    }
    getSuscripciones();
  }, []);

  return <div>
    <div className="">
      <h1><i className="fas fa-bell" /> Notificaciones
            </h1>
    </div>
    <div className="mg-top card">
      <div className="card-body">
        <h3><i className="fas fa-arrow-left" onClick={goBack} />  Nueva notificación</h3>

        <form onSubmit={handleSubmit}>
          <div className="mg-top row">
            <div className="col-md-4 form-group">
              <label className="title">Título</label>
              <input className="form-control" name="title" value={form.title} onChange={handleChangeFormsValue} placeholder="Título de notificación" />
            </div>

            <div className="col-md-4 form-group">
              <label className="title">Tema </label>
              <select className="form-control" name="topic" value={form.topic} onChange={handleChangeFormsValue} required>
                <option>Selecciona tema</option>
                <option value="general">General</option>
                {suscripciones.map((s) => (
                  <option key={s.id_suscripcion} value={s.topics}>{s.nombre}</option>
                ))}
              </select>
            </div>
            <div className="col-md-4 form-group">
              <label className="title">Fecha programada (opcional)</label>
              <DateTime
                closeOnSelect={false}
                dateFormat={"DD/MMM/YYYY"}
                placeholder="seleccione fecha"
                timeFormat={true}
                onChange={newDate => handleChangeFormsValue({ target: { name: "send_date", value: moment(newDate).toISOString() } })}
                value={form.send_date && moment(form.send_date).format('DD/MMM/YYYY h:mm a')}
                // inputProps={{ required: "required" }}
              />
            </div>

            <div className="col-md-12 form-group">
              <label className="title">Mensaje</label>
              <textarea className="form-control" placeholder="Escriba su mensaje aquí" name="message" value={form.message} onChange={handleChangeFormsValue} required />
            </div>

          </div>

          <div className="mg-top tac">
            <button className="btn btn-success" type="submit">Guardar notificación</button>
          </div>
        </form>
      </div>
    </div>

  </div>
}

export default NewNotification;