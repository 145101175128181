import React from 'react';
import PropTypes from 'prop-types';
import styles from './datetimePicker.module.css';

function dateToString(date) {
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day.toString();
  let hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let min = date.getMinutes();
  min = min < 10 ? `0${min}` : min;

  return `${date.getFullYear()}-${month}-${day}:${hours}:${min}`;
}

const DatetimePicker = ({ name, label, customClass, handleInputChange, onChangeCallback, value, valid, touched, errors, disabled, noMinutes }) => {
  const datetime = React.useMemo(() => {
    const datestring = typeof value === 'string' ? value : dateToString(value);

    const [date, hours, minutes] = datestring.split(':');
    return { date: date || '', hours: hours || '', minutes: minutes || '' };
  }, [value]);

  const handleHandle = data => {
    handleInputChange(data);
    // onChangeCallback(data);
  }

  const handleOnChange = event => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    const {
      target: { name: inputName, value: inputValue },
    } = event;
    const datestring = typeof value === 'string' ? value : dateToString(value);
    let [date, hours, minutes] = datestring.split(':');
    if (!date) {
      const today = new Date();
      date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    }

    switch (inputName) {
      case 'picker-date': {
        handleHandle({
          target: {
            name,
            value: new Date(`${inputValue}:${hours ? hours : '00'}:${minutes ? minutes : '00'}`),
          },
          type: 'change',
        });
        break;
      }
      case 'picker-hours': {
        let h = parseInt(inputValue, 10);
        h = h > 23 ? 23 : h;
        h = h < 10 ? `0${h}` : h.toString();
        if (isNaN(h)) {
          h = '';
        }
        handleHandle({
          target: {
            name,
            value: new Date(`${date}:${h}:${minutes ? minutes : '00'}:00`),
          },
          type: 'change',
        });
        break;
      }
      case 'picker-minutes': {
        let m = parseInt(inputValue, 10);
        m = m > 59 ? 59 : m;
        m = m < 10 ? `0${m}` : m.toString();
        if (isNaN(m)) {
          m = '';
        }
        handleHandle({
          target: {
            name,
            value: new Date(`${date}:${hours ? hours : '00'}:${m}:00`),
          },
          type: 'change',
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={`wafo-wrapper ${customClass}`}>
      <div className="form-group wafo-input">
        {label && <label htmlFor="picker-datetime">{label}</label>}
        <div className={styles['wrapper']}>
          <input
            type="date"
            name="picker-date"
            className={`form-control ${styles['input-date']}`}
            value={datetime.date}
            onChange={handleOnChange}
            // onChange={({ target: { value } }) => console.log(value)}
            disabled={disabled}
          />
          <input
            type="number"
            name="picker-hours"
            className={`form-control no-arrows ${styles['input-hours']}`}
            placeholder="HH"
            min="0"
            max="23"
            autoComplete="off"
            value={datetime.hours}
            onChange={handleOnChange}
            onClick={event => event.target.select()}
            disabled={disabled}
            ignoreinput="true"
          />
          <span className={`${styles['input-colons']} ${disabled && styles['disabled']}`}>:</span>
          {!noMinutes && (
            <input
              type="number"
              name="picker-minutes"
              className={`form-control no-arrows ${styles['input-minutes']}`}
              placeholder="MM"
              min="0"
              max="59"
              autoComplete="off"
              value={datetime.minutes}
              onChange={handleOnChange}
              onClick={event => event.target.select()}
              disabled={true}
              ignoreinput="true"
            />
          )}
          {noMinutes && (
            <input type="number" className={`form-control no-arrows ${styles['input-minutes']}`} value="00" disabled />
          )}
        </div>
      </div>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

DatetimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customClass: PropTypes.string,
  handleInputChange: PropTypes.func,
  value: PropTypes.any,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

DatetimePicker.defaultProps = {
  label: '',
  customClass: '',
  handleInputChange: f => f,
  value: '',
  valid: false,
  touched: false,
  errors: [],
  disabled: false,
};

export default DatetimePicker;
