import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { apiGetResidentePayments, apiGetSingleResidente, apiUpdateResidente, apiUpdateResidenteSuscripcion } from '../../API/api';
import Table from '../../Components/ListManager/table';
import ModalActivar from './modalActivar';
import moment from 'moment';
import MonthsOfYear from '../../Components/customInputs/monthsOfYear/monthsOfYear';

const ResidentDetails = (props) => {
    const { showLoader, NotificationManager } = props;

    const [residente, setResidente] = React.useState({
        nombres: '',
        apellidos: '',
        telefono: '',
        correo: '',
        asociados: []
    });
    const [payments, setPayments] = React.useState([]);

    const [modal, setModal] = React.useState(false);

    const getResidente = React.useCallback(async () => {
        showLoader(true);
        apiGetSingleResidente(props.match.params.id)
            .then(response => {
                if (!response.message) {
                    let obj = { ...response.residente, asociados: [], suscripcion: {} };
                    if (response.hasOwnProperty("asociados")) {
                        obj.asociados = response.asociados;
                    }
                    if (response.hasOwnProperty("suscripcion")) {
                        obj.suscripcion = response.suscripcion;
                    }
                    setResidente(obj);
                }
            })
            .catch()
            .finally(() => {
                showLoader(false);
            });
        apiGetResidentePayments(props.match.params.id)
            .then(response => {
                setPayments(response.rows);
            });
    }, [props.match.params.id, showLoader]);

    React.useEffect(() => {
        getResidente();
    }, [getResidente]);

    const mapResidents = (data) => {
        let datatable = [];

        datatable = data.map((elm, index) => ({
            index: index + 1,
            residentes: elm.nombres + " " + elm.apellidos,
            correo: elm.correo,
            telefono: elm.telefono,
            opciones: <React.Fragment>
                <Link to={"/usuarios/detalles/" + elm.id_residente} className="btn btn-primary"><i className="fas fa-list" /> Detalles</Link>
            </React.Fragment>
        }))

        return datatable;
    }

    const handleEdit = () => {
        const location = {
            pathname: `${props.location.pathname}`,
        }
        if (props.location.state) {
            location.state = props.location.state;
        }
        props.history.replace(location);
        //console.log(`${props.location.pathname}/editar/${item[keyLabel]}`);
        const ruta = "/usuarios/editar/" + residente.id_residente;
        props.history.push(ruta);
    }

    const handleInactive = () => {
        showLoader(true);
        apiUpdateResidente(residente.id_residente, { residente: { activo: !residente.activo } })
            .then(response => {
                if (!response.message) {
                    let obj = { ...response.residente, asociados: [], suscripcion: {} };
                    if (response.hasOwnProperty("asociados")) {
                        obj.asociados = response.asociados;
                    }
                    if (response.hasOwnProperty("suscripcion")) {
                        obj.suscripcion = response.suscripcion;
                    }
                    setResidente(obj);
                    let message = obj.activo ? "Se ha habilitado al residente." : "Se ha inhabilitado al residente";
                    NotificationManager.success(message, '¡Exito!', 5000);
                }
            })
            .catch(() => {
                NotificationManager.error('Ocurrió un error al guardar los datos.', '¡Error!', 5000);
            })
            .finally(() => {
                showLoader(false);
                toggleModal();
            });
    }

    const goBack = () => {
        props.history.goBack();
    }

    const toggleModal = () => {
        setModal(!modal);
    }

    const mapPayments = React.useMemo(() => {
        if (!Array.isArray(payments) || !payments.length) return [];
        return payments.map(pay => ({
            id: `#${pay.id_suscripcion_movimiento}`,
            date: moment(pay.createdAt).format("DD-MMM-YYYY hh:mm a"),
            amount: `$${pay.importe}`
        }));
    }, [payments]);

    const updateSuscription = async (year, month) => {
        const newEndDate = moment(`${year}-${month}-01`, 'yyyy-MM-DD');
        newEndDate.add(1, 'month');

        showLoader(true);

        apiUpdateResidenteSuscripcion(props.match.params.id, {
            id_suscripcion: residente?.suscripcion?.detalle_suscripcion.id_suscripcion,
            fecha_fin: newEndDate.format('yyyy-MM-DD')
        })
            .catch(error => {
                NotificationManager.error('Ocurrió un error al actualizar la suscripción.', '¡Error!', 5000);
                console.error(error);
            })
            .finally(() => {
                showLoader(false);
                getResidente();
            });
    };

    return <div >
        <div className="mg-top">
            <h1><i className="fas fa-users" /> Usuarios
        </h1>
        </div>
        <div className="card mg-top">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h4><i className="fas fa-arrow-left" onClick={goBack} /> Información de usuario</h4>
                    </div>
                    <div className="col-md-6 tar btn-container">
                        <button className="btn btn-primary" onClick={handleEdit}><i className="fas fa-lock" /> Cambiar contraseña</button>
                        <button className="btn btn-success" onClick={handleEdit}><i className="fas fa-edit" /> Editar</button>
                        <button className={residente.activo ? "btn btn-danger" : "btn btn-info"} onClick={toggleModal}><i className="fas fa-power-off" /> {residente.activo ? "Inhabilitar" : "Habilitar"}</button>
                    </div>
                </div>

                <div className="row mg-top">
                    <div className="col-md-3">
                        <label className="title">No. socio</label>
                        <p>{residente.numero_socio? residente.numero_socio: "No asignado"}</p>
                    </div>
                    <div className="col-md-3">
                        <label className="title">Nombre</label>
                        <p>{residente.nombres + ' ' + residente.apellidos}</p>
                    </div>
                    <div className="col-md-3">
                        <label className="title">Telefono</label>
                        <p>{residente.telefono}</p>
                    </div>
                    <div className="col-md-3">
                        <label className="title">Correo Electrónico</label>
                        <p>{residente.correo}</p>
                    </div>
                    <div className="col-md-3">
                        <label className="title">Fecha de nacimiento</label>
                        <p>{residente.fecha_nacimiento ? moment(residente.fecha_nacimiento).format("DD-MMM-YYYY") : "No disponible"}</p>
                    </div>
                    {residente && residente.id_titular && (
                        <div className="col-md-3">
                            <label className="title">Titular de suscripción</label>
                            <p>{residente.titular.codigo_residente}</p>
                        </div>
                    )}
                </div>

                <div className="row mg-top">
                    <div className="col-md-3">
                        <label className="title">Suscripcion</label>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td>Tipo</td>
                                <td>{residente.suscripcion ? residente.suscripcion.detalle_suscripcion.nombre : "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Estado</td>
                                <td>{residente.suscripcion?.estatus_suscripcion === 1 ? "Regular" : "Pendiente de pago"}</td>
                            </tr>
                            <tr>
                                <td>Importe</td>
                                <td>{residente.suscripcion ? `$${(residente.suscripcion.detalle_suscripcion.precio).toFixed(2)}` : ''}</td>
                            </tr>
                        </table>
                    </div>
                    {residente.suscripcion && !residente.id_titular && (
                        <React.Fragment>
                            <div className="col-md-3">
                                <label className="title">Valida hasta</label>
                                <MonthsOfYear currentEnd={residente.suscripcion.fecha_fin} updateSuscription={updateSuscription} />
                            </div>
                        </React.Fragment>
                    )}
                </div>

                {residente?.id_residente && !residente.id_titular && (
                    <div className="mg-top">
                        <h4>{residente.asociados.length} Asociados</h4>
                        <div className="table-responsive">
                            <Table
                                columns={[]}
                                data={mapResidents(residente.asociados)}
                                zeroRecord="No se han asignado asociados."
                            />
                        </div>
                    </div>
                )}
                
                {residente?.id_residente && !residente.id_titular && (
                    <div className="mg-top">
                        <h4>Movimientos</h4>
                        <div className="table-responsive">
                            <Table
                            columns={["Transacción", "Fecha de movimiento", "Importe"]}
                            data={mapPayments}
                            zeroRecord="No se han registrado movimientos."
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
        <ModalActivar
            show={modal}
            name={residente.nombres + ' ' + residente.apellidos}
            word={residente.activo ? "inhabilitar" : "habilitar"}
            handleClose={toggleModal}
            confirm={handleInactive}
        />
    </div>
}

export default withRouter(ResidentDetails);